import React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby";
import Img from "gatsby-image";

import Head from "../components/head";
import Layout from "../components/layout";
import Section from "../components/section";

const PageTitle = "Biographie";

export default ({ data }) => (
  <div>
    <Head PageTitle={PageTitle} PageSlug="/about/" />

    <Layout>
      <Section Style="secondary uk-light uk-text-center">
        <h3>{PageTitle}</h3>
        <div>
          Passionné de technologies, j'aime le travail de terrain et souhaite
          mettre les outils numériques au service de notre environnement...
        </div>
      </Section>

      <Section Style="muted">
        <h4>Geek de Terrain</h4>

        <div className="uk-width-1-2@s uk-width-1-3@m uk-align-center uk-align-right@s uk-margin-remove-adjacent">
          <Img fluid={data.avatar.childImageSharp.fluid} />
          <small>Sur le terrain : alpages du Mercantour</small>
        </div>

        <p>
          Mon profil est particulier puisque je suis à l'aise dans 2 domaines
          très différents : le <strong>monde virtuel des technologies</strong>{" "}
          d'un côté, et de l'autre, la <strong>réalité du terrain</strong> en
          pleine Nature.
        </p>
        <p>
          Après une adolescence motivée par toutes sortes de jeux vidéos, j'ai
          converti cet enthousiasme dans quelque chose de plus productif : la
          création de mon premier site Internet pour partager ma deuxième
          passion, témoigner des paysages naturels et de la faune sauvage que
          j'observe.
        </p>
        <p>
          Contemplatif face à la Nature des <strong>montagnes</strong>, j'ai
          trouvé un certain équilibre entre mes randonnées en altitude et mes
          activités liées à l'informatique. L'idée d'être une sorte de "geek de
          terrain" me plaît bien.
        </p>
      </Section>

      <Section Style="default">
        <h4>Autodidacte et Polyvalent</h4>

        <div className="uk-width-1-2@s uk-width-1-3@m uk-align-center uk-align-left@s uk-margin-remove-adjacent">
          <Img fluid={data.avatar1.childImageSharp.fluid} />
          <small>Après 35 jours de marche dans les Alpes</small>
        </div>

        <p>
          Après un cursus scientifique en lycée agricole, j'ai terminé des
          études d'
          <strong>ingénieur de l'environnement</strong> au cours desquelles j'ai
          découvert les acteurs de la Nature... Assez rapidement, j'ai compris
          l'utilité des nouvelles technologies pour la gestion des espèces et
          des espaces naturels, et j'ai décidé d'en faire ma spécialité.
        </p>
        <p>
          En plus d'être autodidacte, mes expériences professionnelles m'ont
          permis de développer davantage mes{" "}
          <Link to="/skills/">compétences</Link> en informatique. Aujourd'hui,
          je peux proposer mes <Link to="/services/">services</Link> pour mettre
          en place des solutions numériques alliant{" "}
          <strong>
            <Link to="/services/webapp">web</Link>
          </strong>
          ,{" "}
          <strong>
            <Link to="/services/database">bases de données</Link>
          </strong>{" "}
          et{" "}
          <strong>
            <Link to="/services/mapping">cartographie</Link>
          </strong>
          , avec une mention particulière sur la{" "}
          <strong>
            <Link to="/services/nomad">mobilité nomade</Link>
          </strong>{" "}
          et l'accès aux outils <strong>sur le terrain</strong>.
        </p>
        <p>
          Je me forme aux dernières technologies en fonction des projets qui se
          présentent. "Tout est possible" en informatique, il y a toujours une
          solution technique aux problèmes ou un outil numérique adapté aux
          besoins de mes clients et collaborateurs.
        </p>
        <p>
          En bref, de cette polyvalence que je vous propose, à vous de choisir
          les casquettes dont vous avez besoin : Développeur, Intégrateur,
          Webmaster, Informaticien, Géomaticien, Geek, Infographiste,
          Conseiller, Formateur, Ingénieur, Agent de terrain, Randonneur...
        </p>
        <div className="uk-margin-large">
          <blockquote>
            N'hésitez pas à me <Link to="/contact/">contacter</Link> pour me
            parler de votre projet, et même si celui-ci ne concerne pas une
            thématique "espaces naturels", je pourrai sans doute vous aider !
          </blockquote>
        </div>
      </Section>

      {/* <Section Style="muted uk-padding-small">
        <div className="uk-flex uk-flex-center uk-padding-large">
          <div className="uk-width-1-2 uk-width-1-3@s uk-width-1-4@m">
            <Img fluid={data.logo.childImageSharp.fluid} />
          </div>
        </div>
      </Section> */}
    </Layout>
  </div>
);

export const query = graphql`
  query images {
    avatar: file(relativePath: { eq: "pages/avatar.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, maxHeight: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    avatar1: file(relativePath: { eq: "pages/avatar1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, maxHeight: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

// logo: file(relativePath: { eq: "pages/logo-guslyon-dark.png" }) {
//   childImageSharp {
//     fluid(maxWidth: 400) {
//       ...GatsbyImageSharpFluid
//     }
//   }
// }
